import { NgModule, inject } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { PreloadingStrategyService } from '@libs/shared/services';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'barcode/:value',
        loadChildren: () =>
          import('@libs/scylla/generated-code-image/generated-code-image.module').then(
            m => m.GeneratedCodeImageModule
          ),
      },
      {
        path: 'landing/:id',
        loadChildren: () =>
          import('@libs/scylla/landing-page/landing-page.module').then(m => m.LandingPageModule),
      },
      {
        path: '',
        canMatch: [(...args: [Route, UrlSegment[]]) => inject(AuthGuard).canLoad(...args)],
        loadChildren: () => import('@libs/scylla/scylla.module').then(m => m.ScyllaModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      canceledNavigationResolution: 'computed',
      preloadingStrategy: PreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
