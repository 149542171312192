export const formatUTCDate = (value: string, useFullYear: boolean = true): string => {
  const date = new Date(value);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = useFullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2);

  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');

  return `${paddedMonth}/${paddedDay}/${year}`;
};
