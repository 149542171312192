import { Component, Inject, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthFacade } from '@libs/scylla/auth/+state';
import { WINDOW } from '@libs/shared/providers';
import { JwtDecoderService } from '@libs/shared/services';
import { PendoFacadeService } from '@libs/shared/services/pendo-facade/pendo-facade.service';
import { LoaderService } from '@libs/shared/ui-components/loader';

import { environment } from '@env/scylla';

@UntilDestroy()
@Component({
  selector: 'scylla-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private swUpdate: SwUpdate,
    private authFacade: AuthFacade,
    private jwtService: JwtDecoderService,
    private pendoFacadeService: PendoFacadeService,
    private router: Router,
    private loaderService: LoaderService,
    @Inject(WINDOW) private window: Window
  ) {}

  public ngOnInit(): void {
    const requiresAuth = this.checkPathRequiresAuth();
    if (requiresAuth) {
      this.authFacade.checkSession();
    }

    this.initSubs();
    this.initializePendo();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.showLoader();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loaderService.hideLoader();
      }
    });
  }

  private initSubs(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          untilDestroyed(this),
          filter(event => event.type === 'VERSION_READY')
        )
        .subscribe(() => {
          this.swUpdate.activateUpdate().then(() => {
            this.window.location.reload();
          });
        });

      this.swUpdate.unrecoverable.pipe(untilDestroyed(this)).subscribe(() => {
        this.window.location.reload();
      });
    }
  }

  private checkPathRequiresAuth(): boolean {
    const path = decodeURIComponent(window.location.pathname);
    // check if current path is not a barcode image or landing page
    const barcodeImagePathPattern = /^\/barcode\/[^\/]+$/;
    const landingPagePathPattern = /^\/landing\/[^\/]+$/;

    return !barcodeImagePathPattern.test(path) && !landingPagePathPattern.test(path);
  }

  private initializePendo(): void {
    const userAndTokenObservables: Observable<any>[] = [
      this.authFacade.user$,
      this.jwtService.decodedToken$,
    ];

    combineLatest(userAndTokenObservables)
      .pipe(untilDestroyed(this))
      .subscribe(([user, auth0token]) => {
        if (user && auth0token) {
          let audienceWithSlash = environment.authConfig.authorizationParams?.audience;
          if (audienceWithSlash && !audienceWithSlash.endsWith('/')) {
            audienceWithSlash += '/';
          }

          const tenantId = auth0token[`${audienceWithSlash}tenantId`];

          this.pendoFacadeService.initializePendo(
            {
              id: user.sub,
              full_name: user.name,
              first_name: user.given_name,
              last_name: user.family_name,
              drbUser: 'true',
            },
            {
              id: tenantId,
            }
          );
        }
      });
  }
}
